import { type TransformerOption } from '@cld-apis/types';
import { buildImageUrl, setConfig } from 'cloudinary-build-url';
import emojiRegex from 'emoji-regex';

setConfig({
  cloudName: 'ilagine-com'
});

type ImageBuilder = {
  (transformations?: TransformerOption): string;
  alt: string;
  id: string;
};
const createImages = <ImageType extends Record<string, { id: string; alt: string }>>(images: ImageType) => {
  const imageBuilders: Record<string, ImageBuilder> = {};
  for (const [name, { id, alt }] of Object.entries(images)) {
    imageBuilders[name] = getImageBuilder(id, alt);
  }
  return imageBuilders as { [Name in keyof ImageType]: ImageBuilder };
};

function getImageBuilder(id: string, alt: string = ''): ImageBuilder {
  function imageBuilder(transformations?: TransformerOption) {
    return buildImageUrl(id, { transformations });
  }
  imageBuilder.alt = alt;
  imageBuilder.id = id;
  return imageBuilder;
}

const images = createImages({
  ilagine: {
    id: 'v1647916366/ilagine.com/ilagine_dark_gznqbe',
    alt: 'ilagine logo oscuro'
  },
  ilagineWhite: {
    id: 'v1675389598/ilagine.com/logo_light_ile6nf.png',
    alt: 'ilagine logo blanco'
  },
  homepage: {
    id: 'v1672263920/ilagine.com/homepage_eqxaem.png',
    alt: 'ilagine'
  },
  getToKnowCristian: {
    id: 'v1672263921/ilagine.com/aboutme_g1cznl.png',
    alt: 'Dr. Cristian Hidalgo'
  },
  ilagineLight: {
    id: 'v1647916313/ilagine.com/ilagine_qqzbe9',
    alt: 'ilagine logo claro'
  },
  realVideos: {
    id: 'v1672506409/ilagine.com/real-videos_fdrq1r.png',
    alt: 'videos reales'
  },
  instructiveBlog: {
    id: 'v1672710618/ilagine.com/instructive-blog_fl8q5d',
    alt: 'blog instructivo'
  },
  understandSolveIssue: {
    id: 'v1672715790/ilagine.com/v-issues_ovqk61.png',
    alt: 'entiende a resolver problema vaginal'
  },
  fourthyFour: {
    id: 'v1647917241/ilagine.com/404_xtzewb',
    alt: '404 page'
  },
  avatar: {
    id: 'v1647917488/ilagine.com/avatar_gnbr0w',
    alt: 'avatar'
  },
  feature: {
    id: 'v1682097833/ilagine.com/infeccion-urinaria_a3uva2.png',
    alt: 'Diferencias entre infecciones vaginales e infecciones urinarias'
  },
  defaultUser: {
    id: 'v1697559094/ilagine.com/rzmr129le2i0ouqafbxs.png',
    alt: 'Default user image'
  }
});

function getImgProps(
  imageBuilder: ImageBuilder,
  {
    widths,
    sizes,
    transformations
  }: {
    widths: Array<number>;
    sizes: Array<string>;
    transformations?: TransformerOption;
  }
) {
  const averageSize = Math.ceil(widths.reduce((a, s) => a + s) / widths.length);

  return {
    alt: imageBuilder.alt,
    src: imageBuilder({
      quality: 'auto',
      format: 'auto',
      ...transformations,
      resize: { width: averageSize, ...transformations?.resize }
    }),
    srcSet: widths
      .map(width =>
        [
          imageBuilder({
            quality: 'auto',
            format: 'auto',
            ...transformations,
            resize: { width, ...transformations?.resize }
          }),
          `${width}w`
        ].join(' ')
      )
      .join(', '),
    sizes: sizes.join(', ')
  };
}

function getSocialImageWithPreTitle({
  title,
  preTitle,
  featuredImage: img,
  url
}: {
  title: string;
  preTitle: string;
  featuredImage: string;
  url: string;
}) {
  const vars = `$th_1256,$tw_2400,$gw_$tw_div_24,$gh_$th_div_12`;

  const encodedPreTitle = doubleEncode(emojiStrip(preTitle));
  const preTitleSection = `co_rgb:a9adc1,c_fit,g_north_west,w_$gw_mul_14,h_$gh,x_$gw_mul_1.5,y_$gh_mul_1.3,l_text:Roboto_50_bold:${encodedPreTitle}`;

  const encodedTitle = doubleEncode(emojiStrip(title));
  const titleSection = `co_white,c_fit,g_north_west,w_$gw_mul_13.5,h_$gh_mul_7,x_$gw_mul_1.5,y_$gh_mul_2.3,l_text:Roboto_100_bold:${encodedTitle}`;

  const profileSection = `c_fit,g_north_west,r_max,w_$gw_mul_4,h_$gh_mul_3,x_$gw_mul_1.5,y_$gh_mul_8,l_ilagine.com:ilagine_dark_gznqbe.png`;
  const nameSection = `co_rgb:E9747D,c_fit,g_north_west,w_$gw_mul_5.5,h_$gh_mul_4,x_$gw_mul_5.5,y_$gh_mul_9,l_text:Roboto_50_bold:${doubleEncode(
    emojiStrip('Dr. Cristian Hidalgo')
  )}`;

  const encodedUrl = doubleEncode(emojiStrip(url));
  const urlSection = `co_rgb:a9adc1,c_fit,g_north_west,w_$gw_mul_9,x_$gw_mul_4.5,y_$gh_mul_9.8,l_text:Roboto_50_bold:${encodedUrl}`;

  const featuredImageIsRemote = img.startsWith('http');
  const featuredImageCloudinaryId = featuredImageIsRemote ? toBase64(img) : img.replace(/\//g, ':');
  const featuredImageLayerType = featuredImageIsRemote ? 'l_fetch:' : 'l_';
  const featuredImageSection = `c_fill,ar_3:4,r_12,g_east,h_$gh_mul_10,x_$gw,${featuredImageLayerType}${featuredImageCloudinaryId}`;

  return [
    `https://res.cloudinary.com/ilagine-com/image/upload`,
    vars,
    preTitleSection,
    titleSection,
    profileSection,
    nameSection,
    urlSection,
    featuredImageSection,
    `c_fill,w_$tw,h_$th/ilagine.com/solid-color-dark_rrsni7.png`
  ].join('/');
}
// https://res.cloudinary.com/ilagine-com/image/upload/$th_1256,$tw_2400,$gw_$tw_div_24,$gh_$th_div_12/co_rgb:a,c_fit,g_north_west,w_$gw_mul_14,h_$gh,x_$gw_mul_1.5,y_$gh_mul_1.3,l_text:Roboto_100_bold:Revisa%2520este%2520art%25C3%25ADculo/co_white,c_fit,g_north_west,w_$gw_mul_13.5,h_$gh_mul_7,x_$gw_mul_1.5,y_$gh_mul_2.3,l_text:Roboto_100_bold:Diferencias%2520entre%2520infecciones%2520vaginales%2520e%2520infecciones%2520urinarias/c_fit,g_north_west,r_max,w_$gw_mul_4,h_$gh_mul_3,x_$gw_mul_2.5,y_$gh_mul_8,l_ilagine.com:ilagine_dark_gznqbe.png/co_rgb:E9747D,c_fit,g_north_west,w_$gw_mul_5.5,h_$gh_mul_4,x_$gw_mul_5.5,y_$gh_mul_9,l_text:Roboto_50_bold:Dr.%2520Cristian%2520Hidalgo/co_rgb:a9adc1,c_fit,g_north_west,w_$gw_mul_9,x_$gw_mul_4.5,y_$gh_mul_9.8,l_text:Roboto_50_bold:localhost%253A3000%252Fblog%252Fdiferencias-entre-infecciones-vaginales-e-infecciones-urinarias/c_fill,ar_3:4,r_12,g_east,h_$gh_mul_10,x_$gw,l_v1682004784:ilagine.com:infeciones_cjmjhf.jpg/c_fill,w_$tw,h_$th/ilagine.com/solid-color-dark_rrsni7.png

function getGenericSocialImage({
  words,
  featuredImage: img,
  url
}: {
  words: string;
  featuredImage: string;
  url: string;
}) {
  const vars = `$th_1256,$tw_2400,$gw_$tw_div_24,$gh_$th_div_12`;

  const encodedWords = doubleEncode(emojiStrip(words));
  const primaryWordsSection = `co_white,c_fit,g_north_west,w_$gw_mul_10,h_$gh_mul_7,x_$gw_mul_3.3,y_$gh_mul_1.5,l_text:Roboto_100_bold:${encodedWords}`;

  const profileSection = `c_fit,g_north_west,r_max,w_$gw_mul_4,h_$gh_mul_3,x_$gw_mul_2.5,y_$gh_mul_8,l_ilagine.com:ilagine_dark_gznqbe.png`;
  const nameSection = `co_rgb:E9747D,c_fit,g_north_west,w_$gw_mul_5.5,h_$gh_mul_4,x_$gw_mul_5.5,y_$gh_mul_9,l_text:Roboto_50_bold:${doubleEncode(
    emojiStrip('Dr. Cristian Hidalgo')
  )}`;

  const encodedUrl = doubleEncode(emojiStrip(url));
  const urlSection = `co_rgb:a9adff,c_fit,g_north_west,w_$gw_mul_5.5,x_$gw_mul_5.5,y_$gh_mul_9.8,l_text:Roboto_50_bold:${encodedUrl}`;

  const featuredImageIsRemote = img.startsWith('http');
  const featuredImageCloudinaryId = featuredImageIsRemote ? toBase64(img) : img.replace(/\//g, ':');
  const featuredImageLayerType = featuredImageIsRemote ? 'l_fetch:' : 'l_';

  const featureImageSection = `c_fit,g_east,w_$gw_mul_11,h_$gh_mul_11,x_$gw,${featuredImageLayerType}${featuredImageCloudinaryId}`;

  const backgroundSection = `c_fill,w_$tw,h_$th/ilagine.com/solid-color-image_1_ejmcik.png`;

  return [
    `https://res.cloudinary.com/ilagine-com/image/upload`,
    vars,
    primaryWordsSection,
    profileSection,
    nameSection,
    urlSection,
    featureImageSection,
    backgroundSection
  ].join('/');
}

function emojiStrip(string: string) {
  return (
    string
      .replace(emojiRegex(), '')
      // get rid of double spaces:
      .split(' ')
      .filter(Boolean)
      .join(' ')
      .trim()
  );
}

function toBase64(string: string) {
  if (typeof window === 'undefined') {
    return Buffer.from(string).toString('base64');
  } else {
    return window.btoa(string);
  }
}

// cloudinary needs double-encoding
function doubleEncode(s: string) {
  return encodeURIComponent(encodeURIComponent(s));
}

export { getGenericSocialImage, getImageBuilder, getImgProps, getSocialImageWithPreTitle, images };
export type { ImageBuilder };
